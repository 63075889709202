@import '../../../../jomalone2_base/scss/theme-bootstrap';

.lc-service-drawer {
  &-v1 {
    #{$rdirection}: 0;
    background: $color-white;
    bottom: 0;
    box-shadow: 0 0 4px $color-black-dark;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 99999;
    @include breakpoint($large-up) {
      #{$rdirection}: 5px;
      bottom: calc(60px + 4em);
      width: 400px;
    }
  }
  h3.lc-service-drawer__header-text {
    color: $color-white;
  }
  &__header-container {
    background: $color-black-dark;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    [dir='rtl'] & {
      flex-direction: row-reverse;
    }
  }
  .device-mobile &__header-text,
  &__header-text {
    color: $color-black;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.6;
    margin: 0;
    text-transform: inherit;
  }
  &__button {
    background: $color-black-dark;
    color: $color-white;
    cursor: pointer;
    display: block;
    font-size: 14px;
    margin: 10px 0;
    padding: 15px 10px;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
  &__body-container {
    background-color: $color-cream;
    color: $color-black-dark;
    font-weight: bold;
    line-height: 1.6;
    padding: 15px;
    a:hover {
      color: $color-white;
    }
  }
  &__logo {
    fill: $color-light-grey;
    height: 2em;
    width: 8em;
  }
  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    @include breakpoint($small-down) {
      height: 100%;
      width: 100%;
      z-index: 9990;
    }
  }
}

.rounded-live-chat-button {
  #{$rdirection}: 5px;
  align-items: center;
  background-color: $color-cream;
  border: 1px solid $color-black-dark;
  border-radius: 0;
  bottom: 60px;
  color: $color-black;
  cursor: pointer;
  display: none;
  justify-content: center;
  padding: 1em;
  position: fixed;
  z-index: 1000;
  &__down-caret {
    border-#{$rdirection}: 2px solid $color-black;
    border-bottom: 2px solid $color-black;
    height: 0.5em;
    margin: 0 10px 5px;
    transform: rotate(45deg);
    width: 0.5em;
  }
}

.LPMcontainer {
  visibility: hidden;
}

.viewcart {
  .rounded-live-chat-button {
    bottom: 85px;
    @include breakpoint($large-up) {
      bottom: 60px;
    }
  }
}

.viewcart,
#payment,
#samples,
#shipping {
  .rounded-live-chat-button {
    bottom: 88px;
    @include breakpoint($large-up) {
      bottom: 60px;
    }
  }
  .helpButton {
    display: none;
  }
}
